import React from "react";

const ImageRow = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="row px-md-3 square-links">
                <div className="d-flex-center col-6 col-md-3 p-0 m-0">
                    <a href="https://plankcooking-web.azurewebsites.net/Shop/SpiceRubs">
                        <img src="SpiceRubs_cube.png" className="img-fluid" alt="" />
                    </a>
                </div>
                <div className="d-flex-center col-6 col-md-3 p-0 m-0">
                    <a href="https://plankcooking-web.azurewebsites.net/Shop/BakingPlanks">
                        <img src="BakingPlanks_cube.png" className="img-fluid" alt="" />
                    </a>
                </div>
                <div className="d-flex-center col-6 col-md-3 p-0 m-0">
                    <a href="https://plankcooking-web.azurewebsites.net/Shop/BBQPlanks">
                        <img src="Cookbooks_cube.png" className="img-fluid" alt="" />
                    </a>
                </div>
                <div className="d-flex-center col-6 col-md-3 p-0 m-0">
                    <a href="https://plankcooking-web.azurewebsites.net/Shop/Cookbooks">
                        <img src="BBQ_Planks_cube.png" className="img-fluid" alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ImageRow;