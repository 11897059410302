import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer text-center">
      <Link to="/about">ABOUT</Link>
      {" | "}
      <a href="https://plankcooking-web.azurewebsites.net/Shop">SHOP</a>
      {" | "}
      <a href="https://plankcooking-web.azurewebsites.net/Reviews">REVIEWS</a>
      {" | "}
      <a href="https://plankcooking-web.azurewebsites.net/Recipes">RECIPES</a>
      {" | "}
      <Link to="/contact">CONTACT</Link>
      {" | "}
      <a href="https://plankcooking-web.azurewebsites.net/Policies">POLICIES</a>
      <p className="m-0 text-center">
        ©Copyright 2020 Plankcooking.com. All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
