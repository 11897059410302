import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/home/home";
import ContactPage from "./components/Contact/ContactPage";
import AboutPage from "./components/About/AboutPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="about" element={<AboutPage />} />{" "}        
      </Route>
    </Routes>
  );
}

export default App;
