import React, { useState } from "react";

const HeaderImage = () => {

    const [backgroundImage, setBackgroundImage] = useState(true);

    const setBackgroundImageActive = () => {
        if (window.innerWidth < 992) {
            setBackgroundImage(false);
        } else {
            setBackgroundImage(true);
        }
    }

    window.addEventListener("resize", setBackgroundImageActive);

    return (
        <div className="container-md d-flex-column-center p-0 pt-3 header-container">
            <div className="d-flex-center header-image-parent pb-1 pb-md-0 px-2 px-md-0 m-0">
                {backgroundImage ?
                    <div>
                        <img className="img-fluid header-image-background" src="HeaderImage_background.png" alt="" />
                        <img className="img-fluid header-image" src="HomePg_Rotator_image1a.png" alt="" />
                    </div>
                : 
                <img className="header-image-solo" src="HomePg_Rotator_image1a.png"  alt=""/>
                }
            </div>
        </div>
    )
}

export default HeaderImage;