import React, { useEffect } from "react";
import HeaderImage from "../../components/HeaderImage/HeaderImage";
import ImageRow from "../../components/ImageRow/ImageRow";
import FooterImage from "../../components/FooterImage/FooterImage";

const Home = () => {

   useEffect(() => {
     document.title = "Home | Plankcooking.com";
   }, []);

  return (
    <div className="home-content">
      <HeaderImage />
      <ImageRow />
      <FooterImage />
    </div>
  );
};

export default Home;
