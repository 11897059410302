import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [toggle, setToggle] = useState(false);

  const toggleDropdown = () => {
    setToggle(!toggle);
  };

  const setToActive = () => {
    if (window.scrollY >= 170 || window.innerWidth < 768) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  window.addEventListener("scroll", setToActive);
  window.addEventListener("resize", setToActive);

  return (
    <>
      <div className="d-none d-md-flex justify-content-center top-container">
        <a className="header-logo-link" href="/">
          <img id="header-logo" src="PC_LogoHeader_Home.png" alt="Logo Icon" />
        </a>
        <a
          className="align-self-end"
          href="https://plankcooking-web.azurewebsites.net/Cart"
        >
          <img id="header-cart" src="Cart_Button_main.png" alt="Cart Icon" />
        </a>
      </div>
      <div id="scrollspy"></div>
      <header id="header" className="header sticky-top w-100">
        <nav className="navbar navbar-expand-md navbar-dark w-100">
          <div id="navContent" className="container-md">
            <button
              id="scrollspy-link"
              className={
                isActive
                  ? "nav-link ignore-link active"
                  : "nav-link ignore-link"
              }
              // eslint-disable-next-line no-script-url
              onClick="javascript:void(0)"
            >
              <span></span>
            </button>

            <div id="navAnimationGroup" className="ml-0 mr-auto d-flex">
              <a
                id="logoNavButton"
                className="navbar-brand d-inline-block"
                href="/"
              >
                <img
                  className="img-fluid"
                  src="PC_LogoHeader_Home.png"
                  alt="Logo Icon"
                />
              </a>
              <a
                id="cartNavButton"
                className="navbar-brand d-inline-block"
                href="https://plankcooking-web.azurewebsites.net/Cart"
              >
                <img
                  className="img-fluid"
                  src="Cart_Button_main.png"
                  alt="Cart Icon"
                />
              </a>
            </div>
            <button
              className={
                !toggle ? "navbar-toggler collapsed" : "navbar-toggler"
              }
              type="button"
              data-toggle="collapse"
              data-target="#navbar"
              aria-controls="navbar"
              aria-expanded={toggle ? "true" : "false"}
              aria-label="Toggle navigation"
              onClick={toggleDropdown}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>

            <div
              className={
                !toggle
                  ? "collapse navbar-collapse drop-down"
                  : "collapse navbar-collapse drop-down show"
              }
              id="navbar"
            >
              <ul className="nav navbar-nav mx-auto pt-2 pb-2 pt-md-0 pb-md-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://plankcooking-web.azurewebsites.net/Shop"
                  >
                    Shop
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://plankcooking-web.azurewebsites.net/Reviews"
                  >
                    Reviews
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://plankcooking-web.azurewebsites.net/Recipes"
                  >
                    Recipes
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
