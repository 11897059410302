import React from "react";

const FooterImage = () => {
    return (
            <div className="footer-image d-flex justify-content-center">
                <img src="FooterPlank.png" alt="" />
            </div>
    )
}

export default FooterImage;