import React, { useEffect } from "react";
import "../../contact.css";
import contactUsImage from "../../images/Contact_MenuHeader.png";

const ContactPage = () => {

  useEffect(() => {
    document.title = "Contact Us | Plankcooking.com";
  }, []);

  return (
    <div id="wrapper">
      <main className="container pt-custom">
        <section className="row justify-content-center">
          <div className="col-12 text-center d-lg-none">
            <img
              src={contactUsImage}
              alt="Contact Us Logo"
              className="img-fluid pb-3 pt-4 pt-md-0"
            />
          </div>
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-lg-1 d-none d-lg-block cont-width"></div>
              <div className="col-lg-3 d-none d-lg-flex justify-content-center align-items-start padding-rt">
                <img
                  src={contactUsImage}
                  alt="Contact Us Logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-8">
                <div className="text-light contact-content-background p-3">
                  <div className="w-100 ms-2 dotted-line-top mb-1"></div>
                  <div className="d-flex align-items-center mb-3">
                    <h6 className="fw-normal ms-2 mb-0 content-title content-width text-light-brown dotted-line-right dotted-line-bottom">
                      CONTACT US
                    </h6>
                  </div>
                  <p className="ms-2 mt-0 text-extra-small">
                    You can contact us one of two ways; click one of the email
                    links below to send us an email, or call the customer
                    service number.
                  </p>
                  <div className="pb-2 mb-3 dotted-line-bottom">
                    <div className="ms-2">
                      <p className="ms-2 p-0 m-0 mb-2 text-extra-small">
                        CUSTOMER SERVICE:
                      </p>
                      <a
                        className="ms-2 text-light-brown text-extra-small"
                        href="mailto:chefhowie@plankcooking.com"
                      >
                        ChefHowie@plankcooking.com
                      </a>
                      <p className="ms-2 p-0 m-0 mt-2 mb-2 text-extra-small">
                        Phone: 425.881.0422
                      </p>
                      <p className="ms-2 p-0 m-0 text-extra-small">
                        Fax: 425.881.7338
                      </p>
                    </div>
                  </div>
                  <p className="ms-2 text-extra-small">
                    WHOLESALE OPPORTUNITIES are available, please contact
                    customer service for more information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactPage;
